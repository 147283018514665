import {
    CoachAccessSubscriptionEnum,
    PitchInsightsSubscriptionEnum,
    StaffProductSubscriptionEnum,
    SubscriptionPlanEnum,
} from 'constants/enums';

export enum AuthStep {
    Email = 1,
    Password = 2,
    ThankYou,
}
export type ServerErrors = {
    emailError?: boolean;
    authError?: boolean;
    authErrorMessage?: string;
};
export default interface AuthState extends AuthInfoModel {
    currentStep: AuthStep;
    triedToLogIn: boolean;
    loading: boolean;
    serverError: ServerErrors;
}
export interface AuthInfoModel {
    // Authentification process props
    isSuccessful: boolean;
    isBlocked: boolean;
    errorMessage: string;

    userTypeId: number;
    userId: number;
    playerId: number | null;
    agentId: number | null;
    agencyId: number | null;
    agencyName: string;
    agencyLogo: string;
    agencyBackgroundImage: string;
    adminImpersonateId: number | null;
    oldUserId: number;
    aspNetUserId: string;
    userEmail: string;
    userFirstName: string;
    phone: string;
    phoneCodeAreaId: number | null;
    userLastName: string;
    userFullName: string;
    userPositionName: string;
    userTypeName: string;
    clubName: string;
    shortClubName: string;
    clubLogo: string;
    logoImageFullPath: string;
    squadId: number;
    clubOldApiId: number;
    clubId: number;
    clubPermission: PermissionModel;
    agencyPermission: AgencyPermissionModel;
    currency: CurrencyModel;
    allowedAdminPages: string[];
    languageId: number;
    languageCode: string;
    defaultSeason: number;
    active: boolean;
    debug: string;
    squadIds: number[];
    squadIdWithApiIds: { [key: number]: number };

    // Permissions
    mySquadPageVisible: boolean;
    playerSearchPageVisible: boolean;
    shortListPageVisible: boolean;
    playerAdsPageVisible: boolean;
    clubAdsPageVisible: boolean;
    messagesPageVisible: boolean;
    friendlyMatchesPageVisible: boolean;
    allowCreateNewMessage: boolean;
    plusPitchPageVisible: boolean;
    homePageVisible: boolean;
    loanInsightsPageVisible: boolean;
    isFaPlayerPointsVisible: boolean;
    isAgentsVisible: boolean;
    financialInsightsPageVisible: boolean;
    homePagePlayerTrendingVisible: boolean;
    homePageWellDialogAddUserVisible: boolean;
    homePageWellDialogFriendlyMatchVisible: boolean;
    homePageWellDialogPlayerAvailabilityVisible: boolean;
    homePageWellDialogPlayerPitchVisible: boolean;
    homePageHighPriorityRecommendationVisible: boolean;
    homePageHighPriorityRecommendationMakePlayersAvailable: boolean;
    homePagePerformanceIndicatorVisible: boolean;
    homePagePerformancePlayerAvailabilityVisible: boolean;
    homePagePerformancePlayerPitchesAmountVisible: boolean;
    homePagePerformanceFriendlyAdsAmountVisible: boolean;
    isAgentsFreeTrialActive: boolean;
    // Qatar league
    isLeague: boolean;

    // Event related
    isEvent: boolean;
    eventsPageVisible: boolean;
    showEventRegistrationModal: boolean;
    eventId: number;
    userEventIds: number[];

    // Subscriptions
    subscriptionPlan: SubscriptionPlanEnum | null;
    pitchInsightsSubscription: PitchInsightsSubscriptionEnum;
    isFreemiumSubscription: boolean;

    areaName: string;
    areaId: number | null;
    competitionName: string;
    competitionId: number | null;
    loanInsights: boolean;
    isMasterAgent: boolean;
    adminPersonalizeId: number | null;
    pitchTestInterestTimeframe: number;
    isAgencySubscribed: boolean;
    isSpecialAgencyUser: boolean;
    isSubscriptionEntryPackage: boolean;
    metaInfo: MetaInfo;
    isResetPasswordOnNextLogon: boolean;
    finishedRegistration: boolean;
    termsAgreed: boolean;
    showTermsAndConditionsModal: boolean;
    isNewPlayerForReferralBanner: boolean;
    coachId: number | null;
    staffId: number | null;
    userImpersonateId: number | null;
    isUnblockAccountLoading: boolean;
    isJustReleasedPlayersPageVisible: boolean;
    switchAccountProcessing: boolean;
}
export interface MetaInfo {
    location: string;
    locationCountry: string;
    device: string;
    deviceVersion: string;
    ipAddress: string;
}
export interface PermissionModel {
    id: number;
    clubId: number;
    canSaleLoanPlayers: boolean;
    canUseMatchMaker: boolean;
    isAllLeaguesAvailable: boolean;
    canSendMessages: boolean;
    adsCanSeeClubNames: boolean;
    adsCanPitchPlayers: boolean;
    isAllAdsLeaguesAvailable: boolean;
    availabilityNo: boolean;
    financialInsights: boolean;
    isFaPlayerPointsVisible: boolean;
    isAgentsVisible: boolean;
    availableLeagues: number[];
    availableAdsLeagues: number[];
    squadPageHidden: boolean;
    pitchPageHidden: boolean;
    friendlies: boolean;
    subscriptionSuspended: boolean;
    homePage: HomePagePermissionModel;
    coachProfilePage: boolean;
    staffProductSubscription: StaffProductSubscriptionEnum;
    isStaffProductEnabled: boolean;
}

export interface AgencyPermissionModel {
    customPitch: boolean;
    isLowerTier: boolean;
    transparency: boolean;
    agencyPlayerMarket: boolean;
    coachAccess: CoachAccessSubscriptionEnum | null;
}

export interface HomePagePermissionModel {
    performanceIndicatorHidden: boolean;
    highPriorityRecommendationHidden: boolean;
    wellDialogPlayerAvailabilityHidden: boolean;
    wellDialogPlayerPitchHidden: boolean;
    wellDialogAddUserHidden: boolean;
    wellDialogFriendlyMatchHidden: boolean;
    playerTrendingHidden: boolean;
    performanceIndicatorPlayerAvailabilityHidden: boolean;
    highPriorityPlayerAvailabilityHidden: boolean;
    homePagePerformancePlayerPitchesAmountHidden: boolean;
    homePagePerformanceFriendlyAdsAmountHidden: boolean;
}
export interface CurrencyModel {
    id: number;
    name: string;
}
