import { EventAgendaDayModel } from 'api/virtual-summit/models/event-agenda';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

class Props {
    forAgency?: boolean;
    agendaDays: EventAgendaDayModel[];
}

const Agenda = (props: Props) => {
    const { agendaDays, forAgency } = props;

    return (
        <Section>
            <div className='ep-auth-agenda'>
                <h2 className='ep-auth-agenda__heading'>Agenda</h2>
                <div className='ep-auth-agenda__item-container ep-auth-agenda__item-container-row'>
                    {agendaDays.map((agendaDay) => {
                        console.log(agendaDay);
                        agendaDay;
                        return (
                            <div key={agendaDay.date.toString()} className='ep-auth-agenda__item'>
                                <div className='ep-auth-agenda__date'>
                                    {moment(agendaDay.date).format('dddd Do MMMM').toUpperCase()}
                                </div>
                                <div className='ep-auth-agenda__timeline'>
                                    {agendaDay.date === '2024-11-11T00:00:00' && (
                                        <div className='ep-auth-agenda__timeline-tip'>
                                            All times are EEST (Türkiye)
                                        </div>
                                    )}
                                    {agendaDay.date == '2024-11-12T00:00:00' && (
                                        <div className='ep-auth-agenda__timeline-tip'>
                                            All times are EEST (Türkiye)
                                        </div>
                                    )}
                                    {agendaDay.date == '2024-11-14T00:00:00' && (
                                        <div className='ep-auth-agenda__timeline-tip'>
                                            All times are CET
                                        </div>
                                    )}

                                    <div className='ep-auth-agenda__subtitle'>
                                        {agendaDay.date === '2024-11-11T00:00:00' && (
                                            <>Grand Cevahir Hotel & Convention Center, İstanbul</>
                                        )}
                                        {agendaDay.date === '2024-11-12T00:00:00' && (
                                            <>Grand Cevahir Hotel & Convention Center, İstanbul</>
                                        )}
                                        {agendaDay.date === '2024-11-14T00:00:00' && (
                                            <span className='ep-auth-agenda__badge'>
                                                ONLINE ONLY
                                            </span>
                                        )}
                                    </div>
                                    {agendaDay.items.map((item) => {
                                        const dateRange = [item.startAt, item.endAt]
                                            .filter((x) => x)
                                            .map((x) => moment(x).format('HH.mm'));

                                        return (
                                            <div
                                                key={item.startAt?.toString()}
                                                className='timeline-row'
                                            >
                                                <div className='time'>
                                                    {dateRange.length === 1
                                                        ? `${dateRange[0]}+`
                                                        : dateRange.join(' - ')}
                                                </div>
                                                <div className='description'>{item.name}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Section>
    );
};

const Section = styled.div`
    .ep-auth-agenda {
        margin: 0 auto;
        padding-left: 5px;
        margin-top: 74px;
        margin-bottom: 40px;
        padding-bottom: 0px;

        &__item-container {
            display: flex;
            justify-content: space-between;
        }

        &__item-container-row {
            flex-direction: column;
            .ep-auth-agenda__item {
                &:first-child {
                    margin-bottom: 30px;
                }
            }
        }

        &__item {
            margin-bottom: 40px;
            &:first-child {
                padding-right: 10px;
            }
        }

        @media (max-width: 1000px) {
            &__item-container {
                flex-direction: column;
            }

            &__item {
                margin-bottom: 20px;
                &:first-child {
                    padding: 0;
                }
            }
        }

        &__heading {
            font-size: 40px;
            font-weight: bold;
        }
        &__date {
            font-size: 32px;
            font-weight: 400;
            color: #1d1c1d;
            @media (max-width: 420px) {
                font-size: 28px;
            }
        }

        &__subtitle {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #131a36;
            margin-top: 30px;
            text-transform: uppercase;

            &--red {
                color: #e55843;
            }
        }

        &__badge {
            background: #181c25;
            border-radius: 2px;
            color: white;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            padding: 2px 5px;
        }

        &__timeline {
            margin-top: 10px;
            &-tip {
                color: #e55843;
                font-size: 15px;
                font-weight: 500;
            }
            &-subheader {
                font-size: 24px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 22px;
                margin-top: 32px;
            }
            .timeline-row {
                display: grid;
                grid-template-areas: 'time description';
                grid-template-columns: 120px 1fr;
                gap: 18px;
                margin-top: 15px;
                @media (max-width: 420px) {
                    grid-template-columns: 102px 1fr;
                    gap: 5px;
                }
                .time {
                    grid-area: time;
                    font-size: 19px;
                    color: #0e9655;
                    text-align: left;
                    font-weight: 700;
                    @media (max-width: 420px) {
                        font-size: 17px;
                    }
                }
                .description {
                    grid-area: description;
                    font-size: 19px;
                    font-weight: 700;
                    text-align: left;
                    color: #1d1c1d;
                    @media (max-width: 420px) {
                        font-size: 17px;
                    }
                }
            }
        }
    }
`;

export default Agenda;
