import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import _ from 'lodash';
import { PlayerProfileModel } from "api/player-profile/model";
import {
    PlayerComparisonInfo,
    AvailableSeasonsListItem,
    PlayerComparisonAverage,
    PlayerComparisonStats,
    PlayerComparisonResponse
} from "api/player-profile/model/performance";
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import {
    getAuth,
    getAuthClubId,
    getAuthUserId,
    getAuthSquadId,
    isAgencyUser,
    isPlayerUser,
    isClubUser
} from "store/auth/authReducer";
import { currencyById } from 'constants/currency';
import { openPlayerProfileById } from "utils/open-link";
import { PlayerProfileService } from 'api/player-profile/player-profile.service';
import { ShortlistService } from 'api/shortlist/shortlist-service';
import { PlayerService } from 'api/player/player-service';
import { PlayerRankingService } from 'api/player-ranking/player-ranking.service';
import { PlayerRankingConfigurationResponse, PlayerRankingCriteriaItem, PlayerRankingItemModel, PlayerRankingSelection } from 'api/player-ranking/model';
import { ReceiverRole } from 'api/search-v3/model/declare-interest-definition';
import { SimilarPlayerModel } from 'api/player-profile/model/similar-players';
import { DisplayStatus, PlayerStatus } from 'api/search/model/search-player-item-for-agency';
import { Actions as AgencyGridActions } from 'pages/agency/authorized-agent/player-search/redux/grid.controller';
import { SearchItemType } from 'api/search/model/search-result';
import { Actions as SendMessageActions } from 'pages/player-profile/redux/send-message.controller';
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { PlayerImpactResponse } from 'api/player-profile/model/impact';
import { getCancelTokenSource } from 'axios-config';
import { SuggestionModel, SuggestionType } from "api/player/player-search";
import { getInPercentsPlayers } from 'utils/convertors';
import { MatchHistoryResponse } from "api/player-profile/model/match-history";
import { translate } from 'services/localization';
import { InjuriesSummaryResponse } from 'api/player-profile/model/injuries';
import { AgentPlayerInvitationStatusEnum } from 'api/agency/player/shared/shared-models';
import {Actions as ModalController} from "components/modals/shared-modal-controller";
import { PlayerIdealClubsModel } from 'api/agency/agent/landing/agency-dashboard';

export interface ComparisonData {
    processing: boolean;
    secondPlayerType: number;
    availableSeasons: AvailableSeasonsListItem[];
    defaultSeasons: AvailableSeasonsListItem[];
    availablePlayers: any[];
    radarData: any[];
    selectedSeason: AvailableSeasonsListItem | null;
    selectedPlayer: SuggestionModel;
    sentence: string | null;
    firstPlayerInfo: PlayerComparisonInfo;
    firstPlayerOffensiveStats: PlayerComparisonStats;
    firstPlayerDefensiveStats: PlayerComparisonStats;
    secondPlayerInfo: PlayerComparisonInfo;
    secondPlayerOffensiveStats: PlayerComparisonStats;
    secondPlayerDefensiveStats: PlayerComparisonStats;
    average: PlayerComparisonAverage | null;
    isDefaultState: boolean;

    keyword: string;
    autosuggestsLoading: boolean;
    wasLoaded: boolean;
    wasChanged: boolean;
    players: SuggestionModel[];
}

export interface PlayerRankingData {
    processing: boolean;
    configuration: PlayerRankingConfigurationResponse,
    selectedSeasonId: number,
    selectedComparison: string,
    selectedPositions: string[],
    selectedCharacteristicKey: string,
    currentPlayerId: number,
    displayPage: number,
    items: PlayerRankingItemModel[],
    originalItems: PlayerRankingItemModel[];
    showBatchCount: number;
    selectedTotalStatistic: PlayerRankingCriteriaItem,
    selectedAverage: PlayerRankingCriteriaItem,
    selectedBaseStatistic: PlayerRankingCriteriaItem,
}

export interface AvailableSectionData {
    [key: string]: boolean;
}

export interface AvailableSections {
    career: boolean;
    financials: AvailableSectionData | null;
    impact: AvailableSectionData | null;
    playingStyle: boolean;
    performance: boolean;
    ranking: boolean;
    injuries: AvailableSectionData | null;
    matchHistory: AvailableSectionData | null;
    alternatives: boolean;
}

class State {
    isLoading: boolean;
    processing: boolean;
    declareInterestToClubProcessing: boolean;
    declareInterestToAgentProcessing: boolean;
    inviteToTrialProcessing: boolean;
    agencyActionsProcessing: boolean;
    profile: PlayerProfileModel;
    similarPlayersList: SimilarPlayerModel[];
    playerImpactInfo: PlayerImpactResponse;
    comparison: ComparisonData;
    playerRankingData: PlayerRankingData;
    matchHistoryData: MatchHistoryResponse;
    injuriesInfo: InjuriesSummaryResponse;
    shortlistedIds: number[];
    availableSections: AvailableSections;
    playerIdealClubs: PlayerIdealClubsModel[] | null;
}

const defaultState: State = {
    isLoading: false,
    processing: false,
    declareInterestToClubProcessing: false,
    declareInterestToAgentProcessing: false,
    inviteToTrialProcessing: false,
    agencyActionsProcessing: false,
    profile: null,
    similarPlayersList: null,
    playerImpactInfo: null,
    injuriesInfo: null,
    matchHistoryData: null,
    comparison: {
        processing: false,
        isDefaultState: false,
        secondPlayerType: -1,
        availableSeasons: [],
        defaultSeasons: [],
        availablePlayers: [],
        radarData: [],
        selectedSeason: null,
        selectedPlayer: null,
        sentence: null,
        firstPlayerInfo: null,
        secondPlayerInfo: null,
        firstPlayerOffensiveStats: null,
        firstPlayerDefensiveStats: null,
        secondPlayerOffensiveStats: null,
        secondPlayerDefensiveStats: null,
        average: null,

        keyword: '',
        autosuggestsLoading: false,
        wasLoaded: false,
        wasChanged: false,
        players: [],
    },
    playerIdealClubs: null,
    playerRankingData: {
        processing: false,
        configuration: null,
        selectedSeasonId: 0,
        selectedComparison: '',
        selectedPositions: [],
        currentPlayerId: null,
        displayPage: 1,
        items: [],
        originalItems: null,
        showBatchCount: 10,
        selectedTotalStatistic: null,
        selectedAverage: null,
        selectedBaseStatistic: null,
        selectedCharacteristicKey: '',
    },
    shortlistedIds: [],
    availableSections: {
        career: false,
        financials: null,
        impact: null,
        playingStyle: false,
        performance: false,
        ranking: false,
        injuries: null,
        matchHistory: null,
        alternatives: false
    }
}

const stateController = new StateController<State>("PLAYER_PROFILE/PLAYER_PROFILE_V2", defaultState);

class Actions {
    public static cancelToken = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static initPlayerProfile(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            await dispatch(Actions.getPlayerProfile(playerId));
            dispatch(stateController.setState({ isLoading: false }));

            const profile = Selectors.getProfile(getState());
            if (profile?.rating) {
                dispatch(Actions.loadImpactSummary(playerId));
            }
            dispatch(Actions.sendUserActivity('Viewed Player Profile', null, playerId));
        };
    };

    public static initAdditionalPlayerData(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.getPlayerRankingConfiguration(playerId));
            dispatch(Actions.getMatchHistory(playerId, false));
            dispatch(Actions.loadInjuriesSummary(playerId));
            dispatch(Actions.initPlayerComparison(playerId));
            dispatch(Actions.getSimilarPlayers(playerId));
            dispatch(Actions.loadShortlistedIds());
        }
    }

    public static initCurrentPlayerProfile() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            dispatch(stateController.setState({ isLoading: true }));
            await dispatch(Actions.getCurrentPlayerProfile());
            dispatch(stateController.setState({ isLoading: false }));

            const profile = Selectors.getProfile(getState());
            if (profile?.rating) {
                dispatch(Actions.loadImpactSummary(playerId));
            }
            dispatch(Actions.sendUserActivity('Viewed Player Profile', null, playerId));
        };
    };

    public static initAdditionalCurrentPlayerData() {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            dispatch(Actions.getPlayerRankingConfiguration(playerId));
            dispatch(Actions.getMatchHistory(playerId, true));
            dispatch(Actions.loadInjuriesSummary(playerId));
            dispatch(Actions.initPlayerComparison(playerId));
        }
    }

    public static getPlayerProfile(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const isUserClub = isClubUser(getState());
                const data = await PlayerProfileService.getPlayerProfileById(playerId);
                dispatch(stateController.setState({
                    profile: data,
                }));

                const isXtvExists = data?.xtvHistory?.items?.length > 0;
                const isTransferBenchmarksExists = data?.transferBenchmarksInfo?.transferBenchmarks?.length > 0;
                const isResaleInfoExists = isUserClub && data?.resaleInfo?.contracts?.length > 0;
                const isFinancialsExists = isXtvExists || isTransferBenchmarksExists || isResaleInfoExists;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    availableSections: {
                        ...prevState.availableSections,
                        career: data?.playerCareer.length > 0,
                        playingStyle: !!data.playingStyle?.header,
                        financials: isFinancialsExists ? {
                            xtvValue: isXtvExists,
                            xtvBenchmarks: isTransferBenchmarksExists,
                            resaleValue: isResaleInfoExists,
                        } : null,
                        playerIdealClubs: dispatch?.playerIdealClubs,
                    }
                })));

            } catch (err) {
                console.error(err);
            }
        }
    }

    public static getCurrentPlayerProfile() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const isUserClub = isClubUser(getState());
                const data = await PlayerProfileService.getPlayerProfileCurrent();
                dispatch(stateController.setState({
                    profile: data,
                }));

                const isXtvExists = data?.xtvHistory?.items?.length > 0;
                const isTransferBenchmarksExists = data?.transferBenchmarksInfo?.transferBenchmarks?.length > 0;
                const isResaleInfoExists = isUserClub && data?.resaleInfo?.contracts?.length > 0;
                const isFinancialsExists = isXtvExists || isTransferBenchmarksExists || isResaleInfoExists;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    availableSections: {
                        ...prevState.availableSections,
                        career: data?.playerCareer.length > 0,
                        playingStyle: !!data?.generalInfo?.firstPosition?.name || !!data.playingStyle?.name, // todo: maybe &&
                        financials: isFinancialsExists ? {
                            xtvValue: isXtvExists,
                            xtvBenchmarks: isTransferBenchmarksExists,
                            resaleValue: isResaleInfoExists,
                        } : null
                    }
                })));

            } catch (err) {
                console.error(err);
            }
        }
    }

    public static getSimilarPlayers(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const isUserPlayer = isPlayerUser(getState());
                const data = await PlayerProfileService.getSimilarPlayers(playerId);

                dispatch(stateController.setState({
                    similarPlayersList: data.players,
                }));

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    availableSections: {
                        ...prevState.availableSections,
                        alternatives: !isUserPlayer && data?.players?.length > 0
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }
    public static getMatchHistory(playerId: number, currentPlayer: boolean) {
        return async (dispatch) => {
            try {
                const data = await PlayerProfileService.getMatchHistory(playerId, currentPlayer);

                dispatch(stateController.setState({
                    matchHistoryData: data,
                }));

                const isLastAppearancesExists = data?.lastAppearances?.length > 0;
                const isMatchesExists = data?.seasons?.length > 0 && data?.seasons.some((season) => season.matches.length > 0);
                const isCompetitionsExists = data?.seasons?.length > 0 && data?.seasons.some((season) => season.competitions.length > 0);
                const isMatchHistoryExists = isLastAppearancesExists; // && (isMatchesExists || isCompetitionsExists);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    availableSections: {
                        ...prevState.availableSections,
                        matchHistory: isMatchHistoryExists ? {
                            lastAppearances: isLastAppearancesExists,
                            matches: isMatchesExists,
                            competitions: isCompetitionsExists
                        } : null
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static loadImpactSummary(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const profile = Selectors.getProfile(getState());
                const data = await PlayerProfileService.getImpactSummary(playerId);

                dispatch(stateController.setState({
                    playerImpactInfo: data,
                }));

                const isSummaryExists = data?.summary?.summaries?.length > 0;
                const isLeagueBenchmarksExists = data?.league?.summaries?.length > 0;
                const isImpactExists = profile?.rating && (isSummaryExists || isLeagueBenchmarksExists);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    availableSections: {
                        ...prevState.availableSections,
                        impact: isImpactExists ? {
                            summary: isSummaryExists,
                            leagueBenchmarks: isLeagueBenchmarksExists,
                        } : null
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static loadShortlistedIds() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            const isUserAgent = isAgencyUser(getState());
            const data = isUserAgent
                ? await ShortlistService.loadAgencyShortListedPlayers()
                : await ShortlistService.loadShortListedPlayers();
            dispatch(stateController.setState({ shortlistedIds: data }));
        }
    }

    public static loadInjuriesSummary(playerId: number) {
        return async (dispatch) => {
            try {
                const data = await PlayerProfileService.getInjuriesSummary(playerId);

                dispatch(stateController.setState({
                    injuriesInfo: data,
                }));

                const isSummaryDataExists = data?.summaryData?.length > 0;
                const isSummarySentenceExists = data?.summarySentence;
                const isHistoryDataExists = data?.masterData?.length > 0;
                const isInjuriesExists = isSummaryDataExists || isSummarySentenceExists;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    availableSections: {
                        ...prevState.availableSections,
                        injuries: isInjuriesExists ? {
                            summary: isSummaryDataExists,
                            history: isHistoryDataExists
                        } : null
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static initPlayerComparison(firstPlayerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    processing: true
                }
            })));
            try {
                const comparison = Selectors.getComparison(getState());
                const { secondPlayerType } = comparison;
                const selectedSeasonId = 0;
                const secondPlayerId = 0;

                const data = await PlayerProfileService.getPlayerComparison(selectedSeasonId, firstPlayerId, secondPlayerId, secondPlayerType);

                if (data) {
                    let radarData = Helpers.getRadarData(data);

                    const availableSeasons = data?.availableSeasons?.length > 0 ? data.availableSeasons.map((season) => ({
                        id: season,
                        name: data.seasonNames[season]
                    })) : [];
                    const selectedSeasonUpdated = availableSeasons.find((season) => season.id === data.season);

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        comparison: {
                            ...prevState.comparison,
                            availableSeasons,
                            defaultSeasons: availableSeasons,
                            isDefaultState: true,
                            selectedSeason: selectedSeasonUpdated,
                            firstPlayerInfo: data.firstPlayerInfo,
                            firstPlayerOffensiveStats: data.firstPlayerOffensiveStats,
                            firstPlayerDefensiveStats: data.firstPlayerDefensiveStats,
                            average: data.average,
                            radarData: radarData,
                        }
                    })));

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        availableSections: {
                            ...prevState.availableSections,
                            performance: availableSeasons.length > 0 // only availableSeasons according to #25623
                        }
                    })));
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        processing: false
                    }
                })));
            }
        }
    }

    public static getPlayerComparison(season: number, firstPlayerId: number, secondPlayerId: number, secondPlayerType: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    processing: true
                }
            })));
            try {
                const comparisonState = Selectors.getComparison(getState()) ;
                const defaultSeasons = comparisonState.defaultSeasons;
                const data = await PlayerProfileService.getPlayerComparison(season, firstPlayerId, secondPlayerId, secondPlayerType);

                const availableSeasons = data?.availableSeasons?.length > 0 ? data.availableSeasons.map((season) => ({
                    id: season,
                    name: data.seasonNames[season]
                })) : defaultSeasons;
                const selectedSeasonUpdated = availableSeasons.find((season) => season.id === data.season);

                if (data) {
                    let radarData = Helpers.getRadarData(data);

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        comparison: {
                            ...prevState.comparison,
                            availableSeasons: availableSeasons,
                            selectedSeason: selectedSeasonUpdated,
                            average: data.average,
                            firstPlayerInfo: data.firstPlayerInfo,
                            firstPlayerOffensiveStats: data.firstPlayerOffensiveStats,
                            firstPlayerDefensiveStats: data.firstPlayerDefensiveStats,
                            secondPlayerInfo: data.secondPlayerInfo,
                            secondPlayerOffensiveStats: data.secondPlayerOffensiveStats,
                            secondPlayerDefensiveStats: data.secondPlayerDefensiveStats,
                            radarData: radarData,
                        }
                    })));
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        processing: false
                    }
                })));
            }
        }
    }

    public static onSelectComparisonSeason(season: AvailableSeasonsListItem) {
        return (dispatch, getState: () => AppState) => {
            const comparison = Selectors.getComparison(getState());
            const { firstPlayerInfo, selectedPlayer, secondPlayerType, keyword } = comparison;
            const secondPlayerId = keyword && selectedPlayer?.id ? selectedPlayer?.id : 0;

            dispatch(Actions.sendUserActivity(`Select Season: ${season.name}`, 'Performance'));
            dispatch(Actions.getPlayerComparison(season.id, firstPlayerInfo?.playerId, secondPlayerId, secondPlayerType));

            if (keyword) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        selectedSeason: season,
                        wasChanged: true
                    },
                })));
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        selectedSeason: season,
                        wasChanged: false,
                        secondPlayerInfo: null,
                        secondPlayerType: -1,
                        isDefaultState: true,
                    },
                })));
            }
        }
    }

    public static openPlayerProfile(playerId: number, playerName: string, sectionName: string) {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity(`Opened Player Profile: ${playerName}`, sectionName));
            openPlayerProfileById(playerId);
        }
    }

    public static onOpenPlayerGbeStatus() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Clicked Get Full GBE Calculation', 'Top of Profile'));
            const playerId = Selectors.getProfile(getState())?.id;
            const isUserPlayer = isPlayerUser(getState());
            if (isUserPlayer) {
                window.open(`/player-points`, '_blank');
            } else {
                window.open(`/player-points/${playerId}`, '_blank');
            }

        }
    }

    public static onOpenFA() {
        return (dispatch, getState: () => AppState) => {
            window.open('https://www.thefa.com/football-rules-governance/policies/player-registration/points-based-system', '_blank')
        }
    }

    public static addPlayerToShortList = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            const isUserAgent = isAgencyUser(getState());
            dispatch(stateController.setState({ processing: true }));
            const shortlistIds = Selectors.getShortlistIds(getState());
            const isInShortlist = shortlistIds.includes(playerId);

            try {
                if (isInShortlist) {
                    dispatch(Actions.sendUserActivity('Removed from Shortlist', 'Top of Profile', playerId));
                    isUserAgent ? await ShortlistService.agencyRemoveFromShortlist(playerId) : await ShortlistService.removeFromShortlist(playerId);
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        shortlistedIds: prevState.shortlistedIds.filter(item => item !== playerId)
                    })));
                } else {
                    dispatch(Actions.sendUserActivity('Added to Shortlist', 'Top of Profile', playerId));
                    isUserAgent ? await ShortlistService.agencyAddToShortlist(playerId) : await ShortlistService.addToShortlist(playerId);
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        shortlistedIds: [...prevState.shortlistedIds, playerId]
                    })));
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        }
    }

    public static declareInterestToClub(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ declareInterestToClubProcessing: true }));

            const profile = Selectors.getProfile(getState());

            dispatch(userActivityInsert({
                Message: 'Declared Interest to Club',
                PageName: `Player Profile [Top of Profile]`,
                PlayerId: playerId,
                ClubId: profile.parentSquad ? profile.parentSquad.id : -1,
                ActionType: ActionType.DeclaredInterest,
                PageType: PageType.PlayerProfile,
                UserActivityType: UserActivityType.Action
            }));

            try {
                const shortlistIds = Selectors.getShortlistIds(getState());
                const isInShortlist = shortlistIds.includes(playerId);
                const userId = getAuthUserId(getState());
                const squadId = getAuthSquadId(getState());

                await PlayerService.declareInterestSave(playerId, userId, squadId);

                if (!isInShortlist) {
                    dispatch(Actions.addPlayerToShortList(playerId));
                }

                let interstRolDeclared = { ...profile.declareInterest.declareInterestToRole };
                if (interstRolDeclared.toRole == ReceiverRole.Club) {
                    interstRolDeclared.interestDeclared = true;
                }

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        declareInterest: {
                            ...prevState.profile.declareInterest,
                            interestDeclaredToClub: true,
                            declareInterestToRole: interstRolDeclared
                        }
                    }
                })));

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ declareInterestToClubProcessing: false }));
            }
        }
    }

    public static declareInterestToAgent(playerId: number, agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ declareInterestToAgentProcessing: true }));
            dispatch(userActivityInsert({
                Message: `Declared Interest to Agent`,
                PageName: `Player Profile [Top of Profile]`,
                PageType: PageType.PlayerProfile,
                PlayerId: playerId ? playerId : null,
                AgencyId: agencyId ? agencyId : null,
                ActionType: ActionType.DeclaredInterest,
                UserActivityType: UserActivityType.Action
            }));
            try {
                const profile = Selectors.getProfile(getState());
                const shortlistIds = Selectors.getShortlistIds(getState());
                const isInShortlist = shortlistIds.includes(playerId);
                await PlayerService.declareInterestToAgent(playerId);

                if (!isInShortlist) {
                    dispatch(Actions.addPlayerToShortList(playerId));
                }

                const declareInterestToRole = { ...profile.declareInterest.declareInterestToRole };
                if (declareInterestToRole.toRole == ReceiverRole.Agent) {
                    declareInterestToRole.interestDeclared = true;
                }

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        agencyRelatedInfo: {
                            ...prevState.profile.agencyRelatedInfo,
                            interestWasDeclaredToAgent: true,
                        },
                        declareInterest: {
                            ...prevState.profile.declareInterest,
                            interestDeclaredToAgent: true,
                            declareInterestToRole,
                        }
                    }
                })));

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ declareInterestToAgentProcessing: false }));
            }
        }
    }
    
    public static inviteToTrial(playerId, message) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ inviteToTrialProcessing: true }));
            try {
                await PlayerService.inviteToTrialRequest(playerId, message);
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        isInvitedToTrial: true,
                    }
                })));
                dispatch(ModalController.onClose());
            } catch (e){
                console.error(e);
            } finally {
                dispatch(stateController.setState({ inviteToTrialProcessing: false }));
            }
        }
    }
    
    public static onContactPlayerClick(playerId: number, parentSquadId: number, isProfilePage?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ agencyActionsProcessing: true }));
            try {
                await dispatch(AgencyGridActions.contactPlayer(playerId, parentSquadId, isProfilePage));
                let agencySubState = getState().agency;
                let players = agencySubState.playerSearch.grid.result.items.filter(x => x.type == SearchItemType.Player).map(x => x.player);
                let currentPlayer = players.find(x => x.id === playerId);

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        agencyRelatedInfo: {
                            ...prevState.profile.agencyRelatedInfo,
                            agencyPlayerDisplayInfo: {
                                ...prevState.profile.agencyRelatedInfo.agencyPlayerDisplayInfo,
                                displayStatus: DisplayStatus.ButtonContactPlayerMessageSent,
                                contactPlayerMessageChannel: currentPlayer.agencyPlayerDisplayInfo.contactPlayerMessageChannel
                            }
                        }
                    }
                })));
                dispatch(Actions.sendUserActivity('Clicked Contact Player', 'Top of Profile', playerId));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ agencyActionsProcessing: false }));
            }
        }
    }

    public static onClaimRepresentationClick(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ agencyActionsProcessing: true }));
            try {
                await dispatch(AgencyGridActions.claimRepresentation(playerId));

                dispatch(Actions.sendUserActivity('Clicked Claim Representation on TransferRoom', 'Top of Profile', playerId));

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        agencyRelatedInfo: {
                            ...prevState.profile.agencyRelatedInfo,
                            agencyPlayerDisplayInfo: {
                                ...prevState.profile.agencyRelatedInfo.agencyPlayerDisplayInfo,
                                displayStatus: DisplayStatus.ButtonGetPlayerVerified,
                                status: PlayerStatus.ClaimedNotVerified,
                            }
                        }
                    }
                })));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ agencyActionsProcessing: false }));
            }
        }
    }

    public static onNewMessageClick = (messageReceiverType: ReceiverType, agencyId?: number) => {
        return async (dispatch, getState: () => AppState) => {
            const isAgencyMessageReceiver = messageReceiverType === ReceiverType.Agency;
            if (isAgencyMessageReceiver) {
                const substate = Selectors.getProfile(getState());
                const playerIdState = substate?.id;

                dispatch(userActivityInsert({
                    Message: `Clicked Send Message to Agent`,
                    PageName: `Player Profile [Top of Profile]`,
                    PageType: PageType.PlayerProfile,
                    PlayerId: playerIdState ? playerIdState : null,
                    AgencyId: agencyId ? agencyId : null,
                }));
            } else {
                dispatch(Actions.sendUserActivity('Clicked Send Message to Club', 'Top of Profile'));
            }
            dispatch(SendMessageActions.openModal(messageReceiverType));

        }
    }

    public static sendUserActivity(message: string, pageName?: string, playerId?: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState()).profile;
            const playerIdState = substate?.id;
            const squadId = substate?.currentSquad?.id;
            const isFreeAgent = substate?.parentSquad === null;
            // const { agencyId } = getAuth(getState());
            const isTrackedAgencyId = Selectors.getIsTrackedAgencyId(getState());
            const agencyId = substate?.agencyRelatedInfo?.agencyId

            dispatch(userActivityInsert({
                Message: message,
                PageName: pageName ? `Player Profile [${pageName}]` : 'Player Profile',
                PageType: PageType.PlayerProfile,
                PlayerId: playerIdState ? playerIdState : playerId,
                ClubId: squadId ? squadId : null,
                CurrentClubId: squadId ? squadId : -1,
                AgencyId: isFreeAgent && isTrackedAgencyId ? agencyId : null
            }));
        }
    }
    public static sendTrialUserActivity(message, id) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Player Profile',
                Message: message,
                PlayerId: id,
                PageType: PageType.PlayerProfile,
            }))
        }
    }
    public static openAgencyProfile = () => {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getProfile(getState());
            const playerIdState = substate?.id;
            const agencyName = substate?.agencyRelatedInfo?.agencyName;
            const agencyId = substate?.agencyRelatedInfo?.agencyId;

            dispatch(userActivityInsert({
                Message: `Clicked ${agencyName} Profile Link`,
                PageName: `Player Profile [Top of Profile]`,
                PageType: PageType.PlayerProfile,
                PlayerId: playerIdState ? playerIdState : null,
                AgencyId: agencyId ? agencyId : null,
            }));
            window.open(`/agency-profile/${encodeURIComponent(agencyName)}`, '_blank');
        }
    }

    // Player Autosuggest
    public static createDebounce = () => _.debounce((dispatch, keyword: string) => dispatch(Actions.loadPlayers(keyword)), 1000)
    public static fetchDebounced = Actions.createDebounce();

    public static loadPlayers(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            if (Actions.cancelToken)
                Actions.cancelToken.cancel()

            Actions.cancelToken = getCancelTokenSource();

            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        autosuggestsLoading: true
                    }
                })));
                const clubId = getAuthClubId(getState());
                const squadId = getAuthSquadId(getState());
                const profile = Selectors.getProfile(getState());
                const searchPosition = profile?.generalInfo?.firstPosition?.code;
                const comparison = Selectors.getComparison(getState());
                const { selectedSeason } = comparison;

                const players = await PlayerProfileService.getPlayerSearchSuggestion(selectedSeason.id, keyword, squadId, clubId, searchPosition, Actions.cancelToken.token);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        wasLoaded: true,
                        players: players
                    }
                })));
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        autosuggestsLoading: false
                    }
                })));
            }
        }
    }

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    keyword: keyword,
                    wasChanged: true,
                    players: []
                }
            })));

            if (keyword.length > 0) {
                if (keyword.length > 2) { // start searching after 3 letters typed
                    Actions.fetchDebounced(dispatch, keyword)
                }
            } else {
                dispatch(Actions.clearKeyword())
            }
        }
    }

    public static onKeywordChangeWithoutRefresh(keyword: string) {
        return (dispatch, getState: () => AppState) => {


            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    keyword: keyword,
                    isLoading: false,
                    wasLoaded: false,
                    wasChanged: true,
                }
            })));
        }
    }

    public static clearKeywordWithoutRefresh() {
        return (dispatch, getState: () => AppState) => {
            const comparisonState = Selectors.getComparison(getState()) ;
            const radarData = [...comparisonState.radarData];
            const defaultSeasons = comparisonState.defaultSeasons;

            const radarDataUpdated = radarData.map(x => ({
                ...x,
                secondPlayer: 0,
                secondPlayerFormatted: null
            }));

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    keyword: '',
                    isLoading: false,
                    wasLoaded: false,
                    wasChanged: true,
                    players: [],
                    isDefaultState: false,
                    selectedPlayer: null,
                    secondPlayerInfo: null,
                    secondPlayerDefensiveStats: null,
                    secondPlayerOffensiveStats: null,
                    secondPlayerType: -1,
                    radarData: radarDataUpdated,
                    availableSeasons: defaultSeasons,
                    average: null,
                }
            })));
            Actions.fetchDebounced.cancel()
            if (Actions.cancelToken) { Actions.cancelToken.cancel() }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce()
        }
    }

    public static clearKeyword() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearKeywordWithoutRefresh())
        }
    }

    public static onSelectPlayer(player: SuggestionModel) {
        return (dispatch, getState: () => AppState) => {
            const comparison = Selectors.getComparison(getState());
            const { selectedSeason, firstPlayerInfo } = comparison;
            const keyword = player.suggestionType === SuggestionType.Player
                ? `${player.shortName}${player?.firstPositionCode ? `, ${player?.firstPositionCode}` : ''}${player?.secondPositionCode ? ` (${player?.secondPositionCode})` : ''}`
                : player.competitionName;

            dispatch(Actions.sendUserActivity(`Search: ${keyword}`, 'Performance'));

            // dispatch(Actions.onKeywordChange(player.suggestionType === SuggestionType.Player ? player.englishShortName : player.competitionName));
            dispatch(Actions.onKeywordChangeWithoutRefresh(keyword));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    selectedPlayer: player,
                    secondPlayerType: player.suggestionType
                }
            })));



            dispatch(Actions.getPlayerComparison(selectedSeason.id, firstPlayerInfo.playerId, player?.id, player.suggestionType))
        }
    }

    // ________Player Ranking________

    public static getPlayerRankingConfiguration(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    processing: true
                }
            })));
            try {
                const data = await PlayerRankingService.getConfiguration(playerId);
                if (data) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        playerRankingData: {
                            ...prevState.playerRankingData,
                            configuration: data,
                            selectedSeasonId: data.selection?.selectedSeasonId,
                            selectedComparison: data.selection?.comparisonSandbox,
                            selectedPositions: data.selection?.selectedPositions,
                            selectedCharacteristicKey: data.selection?.characteristicKey,
                            currentPlayerId: Number(playerId),
                            originalItems: data.playerRanking?.items,
                            selectedTotalStatistic: data.playerRanking?.totalStatistic,
                            selectedAverage: data.playerRanking?.averageStatistic,
                            selectedBaseStatistic: data.playerRanking?.baseStatistic,
                        }
                    })));
                }
                dispatch(Actions.getRankingList());
                const rankingList = Selectors.getPlayerRankingData(getState()).items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerRankingData: {
                        ...prevState.playerRankingData,
                        items: rankingList,
                    }
                })));

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    availableSections: {
                        ...prevState.availableSections,
                        ranking: !!data.playerRanking
                    }
                })));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerRankingData: {
                        ...prevState.playerRankingData,
                        processing: false
                    }
                })));
            }
        }
    }

    public static setSelectedSeason(seasonId: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRankingConfiguration(getState()).availableSeasons
            const playerRankingState = Selectors.getPlayerRankingData(getState());
            const comparisonSandboxes = Selectors.getRankingConfiguration(getState()).comparisonSandboxes;
            const selectedSeason = subState.find(i => i.key === seasonId).name

            let comparisonSandbox = playerRankingState.selectedComparison;
            const currentComparisonSandbox = comparisonSandboxes.find(x => (!x.season || x.season === seasonId) && x.key === comparisonSandbox);

            if (!currentComparisonSandbox) {
                comparisonSandbox = comparisonSandboxes.find(x => !x.season || x.season === seasonId)?.key;
            }

            dispatch(Actions.sendUserActivity(`Selected Season: ${selectedSeason}`, 'Ranking'));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    selectedSeasonId: seasonId,
                    selectedComparison: comparisonSandbox,
                }
            })));
            dispatch(Actions.refreshRanking());
        }
    }

    public static setComparisonSandbox(selectedComparisonSandbox: string) {
        return (dispatch, getState: () => AppState) => {
            const sandBoxes = Selectors.getRankingComparisonSandboxes(getState())
            const selectedSandBox = sandBoxes.find(i => i.key === selectedComparisonSandbox).name
            dispatch(Actions.sendUserActivity(`Compare to: ${selectedSandBox}`, 'Ranking'));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    selectedComparison: selectedComparisonSandbox,
                }
            })));
            dispatch(Actions.refreshRanking());
        }
    }

    public static togglePositionCode(positionCode: string) {
        return (dispatch, getState: () => AppState) => {
            const currentPositions = Selectors.getPlayerRankingData(getState()).selectedPositions;
            const indexOfPosition = currentPositions.indexOf(positionCode);
            let newPositions = [];

            if (indexOfPosition === -1) {
                newPositions = [...currentPositions, positionCode];
            } else {
                newPositions = [
                    ...currentPositions.filter(item => item !== positionCode),
                ];
            }

            const positions = newPositions;
            const spacedPositions = positions.toString().replaceAll(',', ', ');
            dispatch(Actions.sendUserActivity(`Selected Position: ${spacedPositions}`, 'Ranking'));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    selectedPositions: newPositions,
                }
            })));
            dispatch(Actions.getRankingList());
        }
    }

    public static setCharacteristicKey(key: string) {
        return (dispatch, getState: () => AppState) => {

            const subState = Selectors.getRankingConfiguration(getState()).availableProperties
            const average = subState.find(i => i.key === key).name

            dispatch(Actions.sendUserActivity(`Selected Parameter: ${average}`, 'Ranking'));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    selectedCharacteristicKey: key,
                }
            })));
            dispatch(Actions.refreshRanking());
        }
    }

    public static refreshRanking() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    processing: true
                }
            })));
            try {
                const { currentPlayerId, selectedCharacteristicKey, selectedSeasonId, selectedComparison, selectedPositions } = Selectors.getPlayerRankingData(getState());
                const selection: PlayerRankingSelection = {
                    selectedSeasonId,
                    comparisonSandbox: selectedComparison,
                    selectedPositions,
                    characteristicKey: selectedCharacteristicKey
                };

                const data = await PlayerRankingService.getRanking(currentPlayerId, selection);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerRankingData: {
                        ...prevState.playerRankingData,
                        originalItems: data.items,
                        selectedTotalStatistic: data.totalStatistic,
                        selectedAverage: data.averageStatistic,
                        selectedBaseStatistic: data.baseStatistic,
                    }
                })));

                dispatch(Actions.getRankingList());

            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerRankingData: {
                        ...prevState.playerRankingData,
                        processing: false
                    }
                })));
            }
        }
    }

    public static getRankingList() {
        return (dispatch, getState: () => AppState) => {
            const rankingState = Selectors.getPlayerRankingData(getState());
            const {
                originalItems,
                showBatchCount,
                selectedPositions,
                currentPlayerId
            } = rankingState;
            const count = rankingState.displayPage * showBatchCount;
            let tempItems = [];

            if (originalItems?.length) {
                tempItems = [...originalItems];
            }

            if (selectedPositions?.length > 0) {
                tempItems = [...tempItems.filter(x => selectedPositions.includes(x.player.positionCode) || x.player.playerId === currentPlayerId)];
            }

            tempItems = [
                ...tempItems.map((item, position) => ({
                    ...item,
                    position: position + 1,
                })),
            ];

            const currentPlayer = tempItems.find(x => x.player.playerId === currentPlayerId);
            const indexOfCurrentPlayer = tempItems.map(x => x.player.playerId).indexOf(currentPlayerId);
            const items = [...tempItems].splice(0, count);

            if (indexOfCurrentPlayer >= count && currentPlayer) {
                items[items.length - 1] = currentPlayer;
            }

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    items: items,
                    originalItems: originalItems,
                }
            })));
        }
    }

    public static onNextPage() {
        return (dispatch, getState: () => AppState) => {
            const { originalItems, showBatchCount, displayPage } = Selectors.getPlayerRankingData(getState());
            const currentSet = displayPage * showBatchCount;
            const lengthTheBiggest = originalItems.length;
            dispatch(Actions.sendUserActivity('Clicked Show More', 'Ranking'));

            if (currentSet < lengthTheBiggest) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerRankingData: {
                        ...prevState.playerRankingData,
                        displayPage: displayPage + 1,
                    }
                })));
            }

            dispatch(Actions.getRankingList());
        }
    }

    public static onShowFirstTen() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendUserActivity('Clicked Show First 10', 'Ranking'));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerRankingData: {
                    ...prevState.playerRankingData,
                    displayPage: 1
                }
            })));
            dispatch(Actions.getRankingList());
        }
    }

    public static openFindOutMoreLink() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity('Clicked Playing Style Info Link', 'Performance Summary'))
            window.open('https://blog.transferroom.com/transferrooms-playing-styles-explained', '_blank');
        }
    }

    public static onpenPartnershipLink() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity('Clicked Data Partnership Info Link', 'Performance Summary'))
            window.open('https://fc.pff.com/', '_blank');
        }
    }
}

class Helpers {
    public static getRadarData(data: PlayerComparisonResponse) {
        if (!data.average) {
            return [];
        }
        const radarData = [];

        Object.keys(data.firstPlayerDefensiveStats).map((key) => {
            radarData.push({
                subject: data.firstPlayerDefensiveStats[key]?.chartDisplayName,
                firstPlayer: data.firstPlayerDefensiveStats ? getInPercentsPlayers(data, key, 'firstPlayerDefensiveStats') : getInPercentsPlayers(data, key, 'average'),
                firstPlayerFormatted: data.firstPlayerDefensiveStats ? data.firstPlayerDefensiveStats[key]?.formattedValue : data.average[key]['averageFormatted'],
                secondPlayer: data.secondPlayerInfo && data.secondPlayerDefensiveStats ? getInPercentsPlayers(data, key, 'secondPlayerDefensiveStats') : getInPercentsPlayers(data, key, 'average'),
                secondPlayerFormatted: data.secondPlayerInfo && data.secondPlayerDefensiveStats ? data.secondPlayerDefensiveStats[key]?.formattedValue : data.average[key]['averageFormatted'],
            })
        });

        Object.keys(data.firstPlayerOffensiveStats).map((key) => {
            radarData.push({
                subject: data.firstPlayerOffensiveStats[key]?.chartDisplayName,
                firstPlayer: data.firstPlayerOffensiveStats ? getInPercentsPlayers(data, key, 'firstPlayerOffensiveStats') : getInPercentsPlayers(data, key, 'average'),
                firstPlayerFormatted: data.firstPlayerOffensiveStats ? data.firstPlayerOffensiveStats[key]?.formattedValue : data.average[key]['averageFormatted'],
                secondPlayer: data.secondPlayerInfo && data.secondPlayerOffensiveStats ? getInPercentsPlayers(data, key, 'secondPlayerOffensiveStats') : getInPercentsPlayers(data, key, 'average'),
                secondPlayerFormatted: data.secondPlayerInfo && data.secondPlayerOffensiveStats ? data.secondPlayerOffensiveStats[key]?.formattedValue : data.average[key]['averageFormatted'],
            })
        });

        return radarData;
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerProfileV2;
    public static getProfile = (state: AppState) => Selectors.getRoot(state).profile;
    public static getMatchHistory = (state: AppState) => Selectors.getRoot(state).matchHistoryData;
    public static getComparison = (state: AppState) => Selectors.getRoot(state).comparison;
    public static getShortlistIds = (state: AppState) => Selectors.getRoot(state).shortlistedIds;
    public static getAvailableSections = (state: AppState) => Selectors.getRoot(state).availableSections;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).processing;
    public static getCurrencySign = (state: AppState) => {
        const currency = getAuth(state).currency;
        return currencyById[currency.id];
    };
    public static getPlayerVideoList = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const clubName = profile?.parentSquad?.name;
        const agencyName = profile?.agencyRelatedInfo?.agencyName;

        let videos = [];
        if (profile?.playerVideos.length > 0) {
            profile.playerVideos.map((item) => {
                videos.push({
                    url: item.url,
                    providedBy: clubName
                })
            })
        }
        if (!!profile?.agencyRelatedInfo?.agentVideo) {
            videos.push({
                url: profile.agencyRelatedInfo.agentVideo,
                providedBy: agencyName
            })
        }

        return videos;
    }
    public static getIsPlayerAvailable = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.playerAvailability?.isAvailableForSale || profile?.playerAvailability?.isAvailableForLoan;
    }
    public static getIsPriorityPlayer = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.status === PlayerStatus.PremiumPlayer;
    }
    public static getIsPlayerLookingForAgent = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.status == PlayerStatus.LookingForAgent;
    }
    public static hasPlayerOpportunities = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.ButtonShowOpportunities;
    }
    public static getIsClaimRepresentationShown = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.ButtonClaimRepresentation;
    }
    public static getIsContactPlayerShown = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.ButtonContactPlayer;
    }
    public static getIsContactPlayerInterestAreasShown = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.interestAreas !== null &&
            (profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.ButtonContactPlayer ||
                profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.ButtonContactPlayerMessageSent);
    }
    public static getIsMessageSentShown = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.ButtonContactPlayerMessageSent;
    }
    public static getIsGetPlayerVerifiedShow = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.ButtonGetPlayerVerified;
    }
    public static getIsClaimedNotVerified = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.status === PlayerStatus.ClaimedNotVerified;
    }
    public static getIsVerificationPending = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        return profile?.agencyRelatedInfo?.agencyPlayerDisplayInfo?.displayStatus === DisplayStatus.LabelVerificationPending;
    }
    public static getIsPlayerFromCurrentSquad = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const currentClubId = getAuthClubId(state);
        const currentSquadId = getAuthSquadId(state);
        const playerCurrentSquadId = profile?.parentSquad ? profile.parentSquad.id : null
        const playerCurrentClubId = profile?.parentSquad ? profile.parentSquad.clubId : null

        if (profile === null) {
            return false
        }

        if (profile?.parentSquad === null && profile?.latestSquad === null) {
            return false
        }

        if (playerCurrentClubId !== null && playerCurrentClubId === currentClubId) {
            return true
        }

        if (playerCurrentClubId === null) {
            return playerCurrentSquadId === currentSquadId
        }

        return false
    }
    public static getIsPlayerFromCurrentAgency = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const agencyId = getAuth(state).agencyId;

        return agencyId === profile?.agencyRelatedInfo?.agencyId && profile?.agencyRelatedInfo?.isAgentPlayerVerified
    }
    public static getSimilarPlayersList = (state: AppState) => Selectors.getRoot(state).similarPlayersList;
    public static getSimilarPlayersSentence = (state: AppState) => {
        const playerShortName = Selectors.getProfile(state)?.playerShortName;
        const isOnlyOneSimilarPlayerInList = Selectors.getSimilarPlayersList(state)?.length === 1;
        return `Player${isOnlyOneSimilarPlayerInList ? '' : 's'} similar to [${playerShortName}]`;
    }
    public static getPlayerImpactInfo = (state: AppState) => Selectors.getRoot(state)?.playerImpactInfo;
    public static getPlayerRankingData = (state: AppState) => Selectors.getRoot(state).playerRankingData;
    public static getRankingConfiguration = (state: AppState) => Selectors.getRoot(state).playerRankingData.configuration;
    public static getInjuriesInfo = (state: AppState) => Selectors.getRoot(state).injuriesInfo;
    public static getRankingComparisonSandboxes = (state: AppState) => {
        const sandboxes = Selectors.getRankingConfiguration(state).comparisonSandboxes
        const countries = translate('apiTexts.areas');
        if (sandboxes) {
            return [
                ...sandboxes.map(item => {
                    if (item.key === 'SHORT_LIST') {
                        return {
                            ...item,
                            name: 'My shortlist' + ' ' + item.name,
                        };
                    }
                    return {
                        ...item,
                        name: countries[item.countryId] + ', ' + item.name,
                    };
                }),
            ];
        }
    };
    public static getIsTrackedAgencyId = (state: AppState) => {
        const profile = Selectors.getProfile(state);

        return profile?.agencyRelatedInfo?.invitationStatus === AgentPlayerInvitationStatusEnum.PreconnectedToAgent ||
            profile?.agencyRelatedInfo?.invitationStatus === AgentPlayerInvitationStatusEnum.SentToPlayer ||
            profile?.agencyRelatedInfo?.invitationStatus === AgentPlayerInvitationStatusEnum.VerifiedByPlayer ||
            profile?.agencyRelatedInfo?.invitationStatus === AgentPlayerInvitationStatusEnum.VerifiedAndConfirmed ||
            profile?.agencyRelatedInfo?.invitationStatus === AgentPlayerInvitationStatusEnum.SentToAgency
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
    Helpers as Helpers,
};
